import { useSelector } from "react-redux";
import { features } from "process";
import { AppHead } from "@/components/generic/app/AppHead";
import { Redirect } from "@/components/generic/navigation/Redirect";
import LayoutEmpty from "@/components/layout/LayoutEmpty";
import { withProvider } from "@/components/wrappers/WithProvider";
import { withOptionalUser, WithUserProps } from "@/components/wrappers/WithUser";
import { featuresSelectors } from "@/core/store/redux/features/selectors";
import { FeaturesProviderSelection } from "@/core/store/redux/features/types";
import { providerSelectors } from "@/core/store/redux/provider/selectors";
import { ProviderStatus } from "@/core/store/redux/provider/types";

const IndexPage: React.FC<WithUserProps> = ({ user }) => {
  return (
    <>
      <AppHead title="Homepage" />
      <LayoutEmpty>{user ? <IndexPageUserWithProvider /> : <Redirect to="/onboarding/start" loading />}</LayoutEmpty>
    </>
  );
};

const IndexPageUser: React.FC = () => {
  const featuresConfig = useSelector(featuresSelectors.config);
  const providerStatus = useSelector(providerSelectors.status);
  let redirect = "/chat";
  if (
    featuresConfig.providerSelection == FeaturesProviderSelection.MANUAL &&
    providerStatus == ProviderStatus.SELECTION_NEEDED
  ) {
    redirect = "/providers";
  }
  return (
    <>
      <AppHead title="Homepage" />
      <LayoutEmpty>
        <Redirect to={redirect} loading />
      </LayoutEmpty>
    </>
  );
};

const IndexPageUserWithProvider = withProvider(IndexPageUser, {
  UnavailableComponent: <IndexPageUser />,
});

export default withOptionalUser(IndexPage);
