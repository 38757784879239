import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Profile, ProfileSummary, ProviderStatus } from "@/core/store/redux/provider/types";

import CustomeErrorPage from "@/pages/custom-error";
import { featuresSelectors } from "@/core/store/redux/features/selectors";
import { FeaturesProviderSelection } from "@/core/store/redux/features/types";
import { providerActions } from "@/core/store/redux/provider/actions";
import { providerSelectors } from "@/core/store/redux/provider/selectors";
import { useGetAllocatedProviderQuery } from "@/core/api/services/provider";
import { getErrorCode } from "@/core/api/utils";
import { Redirect } from "../generic/navigation/Redirect";
import { AppLoading } from "../generic/app/AppState/AppLoading";

export type WithProviderProps = {
  profile: Profile;
};
type WithProviderExcludedProps<P> = Pick<P, Exclude<keyof P, keyof WithProviderProps>>;

type WrappedComponent = React.FC<WithProviderProps & any>;

type WithWrapperProps = {
  Component: WrappedComponent;
} & WithWrapperExtraProps;

type WithWrapperExtraProps = {
  LoadingComponent?: React.ReactNode;
  ErrorComponent?: React.ReactNode;
  UnavailableComponent?: React.ReactNode;
  isOptional?: boolean;
  hideIfNoProvider?: boolean;
};

export function withProvider<P extends WithProviderProps>(
  Component: WrappedComponent,
  ExtraProps?: WithWrapperExtraProps,
): React.FC<WithProviderExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const featuresConfig = useSelector(featuresSelectors.config);
    if (featuresConfig.providerSelection == FeaturesProviderSelection.AUTOMATED) {
      return <WithAutoSelect {...props} Component={Component} {...ExtraProps} />;
    } else if (featuresConfig.providerSelection == FeaturesProviderSelection.MANUAL) {
      return <WithProviderManualSelect {...props} Component={Component} {...ExtraProps} />;
    } else {
      return <CustomeErrorPage title="Invalid provider configuration" />;
    }
  }

  return WithWrapper;
}

const WithAutoSelect: React.FC<WithWrapperProps> = ({
  Component,
  LoadingComponent,
  ErrorComponent,
  UnavailableComponent,
  hideIfNoProvider,
  ...props
}) => {
  const dispatch = useDispatch();
  const profile = useSelector(providerSelectors.profile);
  const providerStatus = useSelector(providerSelectors.status);

  useEffect(() => {
    if (providerStatus == ProviderStatus.NA) {
      dispatch(providerActions.initializeAutomated());
    }
  }, [providerStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!profile && hideIfNoProvider) return null;

  if (providerStatus == ProviderStatus.NA || providerStatus == ProviderStatus.LOADING)
    return LoadingComponent || <AppLoading />;
  else if (providerStatus == ProviderStatus.ERROR)
    return (
      <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
        <CustomeErrorPage
          title="Nu s-a încărcat psihologul"
          message="Nu s-a încărcat psihologul, te rugăm să încerci din nou mai târziu."
        />
      </div>
    );
  else if (providerStatus == ProviderStatus.NO_AVAILABILITY)
    return (
      <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
        <CustomeErrorPage
          title="Niciun psiholog disponibil"
          message="Nu există niciun psiholog disponibil momentan, te rugăm să revii mai târziu."
        />
      </div>
    );
  return <Component {...props} profile={profile} />;
};

const WithProviderManualSelect: React.FC<WithWrapperProps> = ({
  Component,
  LoadingComponent,
  ErrorComponent,
  UnavailableComponent,
  isOptional,
  hideIfNoProvider,
  ...props
}) => {
  const dispatch = useDispatch();
  const profile = useSelector(providerSelectors.profile);
  const providerStatus = useSelector(providerSelectors.status);
  const needsFetch = providerStatus == ProviderStatus.NA;
  const selectionNeeded = providerStatus == ProviderStatus.SELECTION_NEEDED;
  const query = useGetAllocatedProviderQuery(undefined, { skip: !needsFetch });
  const errorCode = getErrorCode(query.error);

  useEffect(() => {
    if (query.data?.provider_id) {
      const provider = query.data as ProfileSummary;
      dispatch(providerActions.initializeProvider(provider));
    }
  }, [query.data?.provider_id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (errorCode == "no_provider_allocation") {
      dispatch(providerActions.setStatus(ProviderStatus.SELECTION_NEEDED));
    } else if (errorCode == "provider_is_inactive") {
      dispatch(providerActions.setStatus(ProviderStatus.SUSPENDED));
    }
  }, [errorCode]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!profile && hideIfNoProvider) return null;

  if (profile || (isOptional && providerStatus != ProviderStatus.LOADING))
    return <Component {...props} profile={profile} />;
  else if (selectionNeeded) {
    return UnavailableComponent || <Redirect to="/providers" />;
  } else if (providerStatus == ProviderStatus.SUSPENDED) {
    return (
      <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
        <CustomeErrorPage
          title="Contul psiholog suspendat"
          message="Contul psihologului este suspendat, te rugăm să contactezi echipa de suport sau sa alegi un alt psiholog."
          anchorText="Alege alt psiholog"
          anchorHref="/providers"
        />
      </div>
    );
  } else if (providerStatus == ProviderStatus.ERROR)
    return (
      <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
        <CustomeErrorPage
          title="Nu s-a încărcat psihologul"
          message="Nu s-a încărcat psihologul, te rugăm să încerci din nou mai târziu."
        />
      </div>
    );
  else if (query.status == QueryStatus.rejected) {
    return <CustomeErrorPage title="Nu s-a încărcat psihologul" />;
  }
  return LoadingComponent || <AppLoading />;
};
