import { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";

import { Link } from "@/components/generic/navigation/Link";
import LayoutEmpty from "@/components/layout/LayoutEmpty";
import AppImage from "@/components/generic/ui/AppImage";

type Props = {
  title: string;
  message?: string;
  anchorText?: string;
  anchorHref?: string;
};

const CustomeErrorPage: NextPage<Props> = ({ title, message, anchorHref, anchorText }) => {
  return (
    <>
      <Head>
        <title>Eroare de server</title>
      </Head>
      <LayoutEmpty>
        <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
          <AppImage
            src="/static/global/images/error-500.webp"
            alt="Eroare 404"
            width={892}
            height={512}
            unoptimized
            className="mb-6 w-full max-w-md"
          />
          <h1 className="mb-2">{title}</h1>
          <p className="md:text-lg mb-6">{message}</p>
          <div className="flex items-center justify-center">
            <Link href={anchorHref ? anchorHref : "/"} className="large">
              {anchorText ? anchorText : "Mergi la prima pagină"}
            </Link>
          </div>
        </div>
      </LayoutEmpty>
    </>
  );
};

export default CustomeErrorPage;
